<template>
  <div>
    <b-card v-if="d_show" :style="'min-height: 500px; background: ' + d_treeListData.style.backGround">
      <b-row style="margin: 0px;">
        <b-col cols="4">
          <b-card footer-class="px-3 py-2" style="height: 100%;">
            <b-row style="margin: 5px;">
              <b-col sm="12" md="12">
                <span style="font-weight: bold; font-size: 50px"> {{ d_wdmr244.label }} </span>
              </b-col>
            </b-row>
            <b-row style="margin: 5px;">
              <b-col cols="12">
                <span style="text-align: center; font-weight: bold; font-size: 50px; color: green"> {{ d_selectedPayment.subscription_time_price.val + ' ' + d_selectedPayment.subscription_time_currency.val.label }} </span> <span style="text-align: center; font-weight: bold; font-size: 25px; color: green"> {{ d_selectedPayment.is_subscription_time_limited.val.value === 'limited' ? ' / ' + d_selectedPayment.subscription_time_number.val + ' ' + d_selectedPayment.subscription_time_type.val.label : ' / Lifetime'}} </span>
              </b-col>
            </b-row>
            <b-row style="margin: 5px;">
            </b-row>
            <b-row style="margin: 5px;">
              <b-col sm="12" md="12" class="text-center">
                <span style="font-weight: bold; font-size: 25px;"> Avantajlar </span>
              </b-col>
            </b-row>
            <b-row style="margin: 5px;">
              <b-col sm="12" md="12">
                <template v-for="(perm, perm_ind) in d_wdmr244.data.general.selected_permissions">
                  {{ perm.name.label }}
                  <template v-if="perm.detail && perm.detail.is_permission_limited && perm.detail.is_permission_limited.val && perm.detail.is_permission_limited.val.value === 'yes' && perm.detail.permission_limit && perm.detail.permission_limit.val && perm.detail.permission_limit.val !== ''">
                    <small>( {{ perm.detail.permission_limit.val }} )</small>
                  </template>
                  <br>
                </template>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="8">
          <CardForm :form-data="formData" :p_paymentApprovedInfo="d_paymentApprovedInfo" @input-card-number="updateCardNumber" @input-card-name="updateCardName" @input-card-month="updateCardMonth" @input-card-year="updateCardYear" @input-card-cvv="updateCardCvv" />
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_paymentModalData.show" @close="d_paymentModalData.show = 0" :width="'1000'">
      <h3 slot="header"></h3>
      <div slot="body">
        <div v-html="d_paymentModalData.html"></div>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_paymentModalData.show = 0">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import GlobalService from '@/services/global';
import SubscriptionService from '@/services/subscription';
import {
  default as CardForm
} from "@/components/widgets/CardForm";
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import store from '@/store';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as router
} from '@/router';

export default {
  name: 'Payment',
  computed: {
    ...mapGetters({})
  },
  mounted: function () {},
  data () {
    return {
      formData: {
        cardName: '',
        cardNumber: '',
        cardNumberNotMask: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: ''
      },
      d_paymentApprovedInfo: { 'approved': 0 },
      d_wdmr244: {},
      user: {},
      d_selectedPayment: {},
      d_show: false,
      d_paymentModalData: { 'show': 0, 'html': '' },
      d_treeListData: {
        'style': {
          'menu': {
            'background': '#ededed',
            'padding': '0',
            'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'border': 'solid 1px black'
          },
          'backGround': 'white',
          'item': {
            'background': '#ededed',
            'color': 'black',
            'margin': '3',
            'width': '300',
            'padding': '3',
            // 'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'boxShadow': '',
            'minHeight': '',
            // 'border': 'solid 1px black',
            'border': '',
            'left_plus': '10'
          }
        },
        'template': {
          'type': '2'
        },
        'list': [],
        'items': [],
        'parent_wdmr_data': '',
        'parent': { // parent and child field means: we can record a child to this parent. But other possible_relation means: this child's key can be added.
          'key': 'wdm232_38',
          'type': 'wdm232',
          'bucket': 'wisdom'
        },
        'child': {
          'reference_wdm_key': 'wdm_wisdom_wdm244_v2.0',
          'type': 'wdm244',
          'bucket': 'wisdom',
          'other_possible_relations': []
        }
      }
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'))
    if (this.$route.query.wdmr244 && (this.$route.query.selection || this.$route.query.selection === 0)) {
      let data = {
        'key': 'wdm244_' + this.$route.query.wdmr244,
        'bucket': 'wisdom'
      };
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_wdmr244 = resp.data.result;
            this.d_selectedPayment = this.d_wdmr244.data.general.subscription_time.list[this.$route.query.selection];
            setTimeout(function () {
              this.d_show = true;
              this.$forceUpdate();
            }.bind(this), 500)
          } else {
            console.log('errorr get_one_cb_document : ', resp.data.message);
          }
        });
    }
  },
  filters: {},
  methods: {
    f_beginPackageOperations: function () {
      let data = {
        'user_id': this.user.id,
        'wdmr244_id': this.d_wdmr244.id,
        'selected_payment': this.d_selectedPayment,
        'creditcard_info': this.formData
      };
      SubscriptionService.add_package_to_user(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            let result = resp.data.result;
          	let body = this.f_preparePaymentBody(result);


            let w = window.open('', '_blank', 'height=500,width=800,toolbar=0,location=0,menubar=0');
            let pageHtml = htmlTemplate
              .replace('##HEADER##', 'Ödeme alınıyor')
              .replace('##BODY##', body)
              .replace('##FOOTER##', 'Lütfen bekleyiniz');
            w.document.write(pageHtml);
            w.document.close();
            w.onload = function () {
              w.focus();
              // w.print();
            };




            // const requestOptions = {
            //   method: result.method,
            //   headers: result.headers,
            //   body: JSON.stringify(result.data)
            // };
            // fetch(result.url, requestOptions)
            //   .then(response => response.json())
            //   .then(data => (console.log(data)));
          } else {
            console.log('errorr get_one_cb_document : ', resp.data.message);
          }
        });
    },
    f_preparePaymentBody: function (result) {
    	let html = '';
    	html += '<form action="'+ result.url + '" method="post" id="three_d_form"/>';
      for (let i in result.data) {
      	html += '<input type="hidden" name="' + i + '" value="' + result.data[i] + '"/>';
    	}
    	html += '<input style="display: hidden" type="submit" value="Öde"/>';
    	html += '<noscript>';
    	html += '<br/>';
    	html += '<div style="text-align:center;">';
    	html += '<h1>3D Secure Yönlendirme İşlemi</h1>';
    	html += '<h2>Javascript internet tarayıcınızda kapatılmış veya desteklenmiyor.<br/></h2>';
    	html += '<h3>Lütfen banka 3D Secure sayfasına yönlenmek için tıklayınız.</h3>';
      html += '<input type="submit" value="3D Secure Sayfasına Yönlen">';
      html += '</div>';
      html += '</noscript>';
      html += '</form>';
      return html
    },
    updateCardNumber (val) {},
    updateCardName (val) {},
    updateCardMonth (val) {},
    updateCardYear (val) {},
    updateCardCvv (val) {}
  },
  components: {
    CardForm,
    Modal
  },
  watch: {
    'd_paymentApprovedInfo.approved': function () {
      if (this.d_paymentApprovedInfo.approved) {
        console.log('bu is tamamdir');
        this.f_beginPackageOperations();
        this.d_paymentApprovedInfo.approved = 0;
      }
    }
  }
};


const htmlTemplate = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <title></title>
  <style type="text/css">
        @page {
        size: A4;
        margin: 10mm 8mm 10mm 8mm;
      }
    @media print {
      .drug_line {
          width: 100%;
          display: inline-block;
        }
      .col-1 {width:8%;  float:left;}
        .col-2 {width:16%; float:left;}
        .col-3 {width:25%; float:left;}
        .col-4 {width:33%; float:left;}
        .col-5 {width:42%; float:left;}
        .col-6 {width:50%; float:left;}
        .col-7 {width:58%; float:left;}
        .col-8 {width:66%; float:left;}
        .col-9 {width:75%; float:left;}
        .col-10{width:83%; float:left;}
        .col-11{width:92%; float:left;}
        .col-12{width:100%; float:left;}

      html, body {
        width: 210mm;
        background: white!important;
        height: 297mm;
      }
      .page-header {
        
      }
      .page-footer {
        position: fixed;
        bottom: 0;
      }
      .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
      }
    }
    .page-footer {
      font-size: 8px;
      position: fixed;
      bottom: 0;
    }
    .text-right {
      text-align: right;
    }
    table, td, tr {
      border: 1px solid black;
    }
    .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
    }

    ##CSS##
  </style>

</head>
<body>
  <div class="page-header">
    ##HEADER##
  </div>
  <div class="page-body">
    ##BODY##
  </div>
  <div class="page-footer">
    ##FOOTER##
  </div>
</body>
<script>
	document.getElementById("three_d_form").submit();
<\/script>
</html>
`;

</script>

